<template>
    <app-transition>
        <Dialog
            class="InformMessage"
            :only-text="true"
            @close="handleClose">
            <div class="text-left">
                <span class="text-left">
                 Dream:   {{ targetDreamGoal.name }}
                </span>
            </div>
            <template v-if="showCelebrationGif">
                <div class="stream-goal-celebration">
                    <img :src="require('@/assets/animations/stream-goal-completed.gif')" alt="Playing..." style="width: 100%; height: 100%;" />
                </div>
            </template>
                <v-progress-linear class="me-2 d-flex mt-5 align-center"
                    :value="progressGoal"
                    :width="5"
                    :color="progressGoalColor"
                    height="20"
                    striped
                    rounded
                >
                    <template v-slot:default>
                        <span class="dream-goal-name">{{ targetDreamGoal.name }}</span>
                        <span class="dream-goal-price">{{ Number(collectedCurrentGoalTips) }} / {{ Number(targetDreamGoal.price) }} {{ currency }} </span>
                    </template>
                </v-progress-linear>
                <template v-if="!showTips">
                <v-row align="center" justify="center" no-gutters class="mt-6">
                    <v-col cols="2" v-for="(item, i) in tips " :key="i" class="mr-4">
                        <div @click="activeTip = i; model.tip=item;" :class="{'select-tip': true, active: i === activeTip}">
                            {{ formatPrice(item) }}
                        </div>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            @click="model.tip = inputTip; activeTip = -1;"
                            @input="model.tip = inputTip"
                            hide-details
                            outlined
                            dense
                            :suffix="currency"
                            v-mask="`###`"
                            v-model="inputTip"
                            text-center
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="isTipNull" class="w-full mt-2 ml-3" align="center">
                    <span class="red--text"><strong>* </strong>
                        {{ $t("dialogs.invalid_tip") }}
                    </span>
                </v-row>            
                <v-row justify="center" class="w-full mt-5">
                    <v-col>
                        <v-btn
                            outlined
                            block
                            @click="save"
                            :loading="$store.getters.loadingBtn(['setting', 'dreamGoalTipSendBtn']) || loading"
                        >
                        {{ this.$t('components.dream_goal.send_button') }}
                    </v-btn>
                    </v-col>
                </v-row>
            </template>
        </Dialog>
    </app-transition>
</template>

<script>
import Radio from "@/components/app/form/Radio";
import GradientButton from "@/components/app/button/GradientButton";
import {CURRENCY_SYMBOL} from "@/configs/constants";
import VerifyDialog from "@/components/app/dialogs/VerifyDialog";
import InformDialog from "@/components/app/dialogs/InformDialog";
import {mapState} from "vuex";
import Dialog from "@/components/app/form/Dialog";

export default {
    name: "CreatorDreamGoalDialog.",
    data: () => ({
        model: {
            radio: 'send',
            tip: 10,
            text: '',
        },
        tips: [5, 10, 15],
        inputTip: '',
        activeTip: 1,
        hidden: true,
        isShowVerifyDialog: false,
        isShowCantSendFunds: false,
        currency: CURRENCY_SYMBOL,
        isTipNull: false,
        progressGoalColor: '#dd3500',
        collectedCurrentGoalTips: 0,
        hasGoalBeenReached: false,
        showCelebrationGif: false,
        showTips:false,
    }),
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        hasRadio: {
            type: Boolean,
            default: true
        },
        blur: {
            type: Boolean,
            default: false
        },
        loading: {
            default: false
        },
        targetVerified: {
            type: Boolean,
        },
        targetUsername: {
            type: String,
        },
        targetDreamGoal: {
            type: Object,
        },
    },
    computed: {
        ...mapState({
            verified: state => state.user.user.data.verified,
            currentUser: (state) => state.user.user.data,
        }),
        canRequest() {
            return this.verified
        },
        currentTip() {
            return this.tips[this.activeTip];
        },
        progressGoal() {       
            if (this.targetDreamGoal) {
                return (this.collectedCurrentGoalTips / this.targetDreamGoal.price) * 100;
            }
        },
    },
    watch: {
        collectedCurrentGoalTips(newVal) {
            if (newVal >= this.targetDreamGoal?.price && !this.hasGoalBeenReached) {
                this.hasGoalBeenReached = true;
                this.showTips = false;
                this.handleGoalCompletion();
            }
            if (newVal >= (this.targetDreamGoal?.price / 2)) {
                this.progressGoalColor = '#dd3500';
            }
            if (newVal >= this.targetDreamGoal?.price) {
                this.progressGoalColor = '#50C878';
            }
        }
    },
    methods: {
        save() {
            if (!this.model.tip || this.model.tip == 0) {
                this.isTipNull = true;
                return;
            }
            if (!this.targetVerified) {
                this.isShowCantSendFunds = true
                return
            }
            this.$emit('save', this.model);
            this.model = {
                ...{
                    radio: 'send',
                    tip: this.currentTip,
                    text: '',
                },
            }

            this.$emit('close');
        },
        formatPrice(price) {
            return price + this.currency;
        },
        handleCloseVerifyDialog() {
            this.isShowVerifyDialog = false;
        },
        handleCloseCantSendFunds() {
            this.isShowCantSendFunds = false
            if (!this.targetVerified && !this.canRequest) {
                this.$emit('close');
            }
        },
        onClickRequest() {
            if (!this.canRequest) {
                this.isShowVerifyDialog = true;
            }
        },
        onClickSend() {
            if (!this.targetVerified) {
                this.isShowCantSendFunds = true;
            }
        },
        handleClose() {
            this.$emit('close');
        },
        async handleGoalCompletion() {
            this.showCelebrationGif = false;
            setTimeout(() => {
                this.showCelebrationGif = false;
            }, 5000);

            // Update goal status in the backend
            try {
                await axios.post(`/api/creator/${this.targetDreamGoal.id}/goal-completed`, {
                    is_achieved: true
                });
            } catch (error) {
                console.error("Failed to update goal status:", error);
            }
        },
    },
    components: {
        GradientButton,
        Radio,
        VerifyDialog,
        InformDialog,
        Dialog
    },
    mounted() {
        this.collectedCurrentGoalTips = this.targetDreamGoal.collected_tips;
    }
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.InformMessage {
    &-title {
        font-size: 20px;
        font-weight: bold;
        line-height: 19px;
        margin: 8px 0;
        display: flex;
        justify-content: center;
    }
}

.backdrop {
    position: fixed;
    top: 0;
    height: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(3px);
    transition: 0.3s;
    z-index: 102;
}

.is-backdrop-show {
    height: 100vh;
}

.swipe-tip-menu {
    background: white;
    position: fixed;
    bottom: 0;
    height: 0px;
    border-radius: 30px 30px 0 0;
    width: 100vw;
    color: black !important;
    transition: 0.2s;
    z-index: 100;
}

.swipe-tip-menu.is-show {
    height: 345px;
}

.control-line {
    background: black;
    height: 4px;
    border-radius: 5px;
    width: 40px;
}

.swipe-tip-menu * {
    color: black;
}

.select-tip {
    background: rgba(237, 237, 237, .3);
    border-radius: 6px;
    padding: 9px;
    width: 100%;
    text-align: center;

}

.select-tip.active {
    background-image: $accent-background;
    color: white
}

.border {
    border: 1px solid rgba(65, 65, 69, .3)
}

.close-button {
    position: absolute;
    right: 30px;
    top: 20px;
    font-size: 1em;
    cursor: pointer;

    &:hover {
        color: map-get($orange, 'darken-4') !important;
    }
}

.RadioGroup-wrapper {
    display: flex;
    justify-content: space-between;
    width: 220px;
}

.dream-goal-name {
    font-size: 0.9em;
    font-weight: bold;
}

.dream-goal-price {
    position: absolute;
    right: 10px;
    font-size: 0.9em;
    font-weight: bold;
}
</style>
