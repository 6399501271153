<template>
    <div>
        <v-container>
            <v-row justify="center">
                <v-col
                    class="mt-2 pa-0 ma-0"
                    @click="handleBackNav"
                    cols="1"
                >
                    <v-icon size="33">mdi-chevron-left</v-icon>
                </v-col>
                <v-spacer />
                <v-col class="text-center mt-4 pa-0 ma-0" cols="1">
                    <v-menu left close-on-content-click offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <icon-menu v-bind="attrs" v-on="on" />
                        </template>
                        <v-list class="UserControls-list">
                            <v-list-item class="UserControls-item">
                                <v-list-item-title @click="handleProfileLink"
                                    >{{ $t("dialogs.profile_link") }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item class="UserControls-item">
                                <v-list-item-title
                                    @click="openReportMessageDialog"
                                    >{{ $t("dialogs.report") }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item class="UserControls-item">
                                <v-list-item-title @click="openBlockDialog">
                                    {{
                                        user.blocked
                                            ? $t("dialogs.unblock")
                                            : $t("dialogs.block")
                                    }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row v-if="!isOwnPage" justify="center">
                <div
                    class="ProfileImage-wrapper"
                    :class="{
                        'without-circle': !user.isExistStory,
                    }"
                >
                    <profile-image
                        v-if="user.id"
                        :has-not-watched-stories="user.hasNotWatchedStories"
                        :is-exist-story="user.isExistStory"
                        size="136"
                        :img="profileImage"
                        :username="user.name"
                        :id="user.id"
                        class="mt-6"
                        :display-user-story="displayUserStory"
                    />
                    <follow-button
                        v-if="user.id"
                        :is-following="user.isFollowing"
                        :user-id="user.id"
                        @click="clickFollow"
                    />
                </div>
            </v-row>
            <v-row v-else justify="center">
                <div class="ProfileImage-wrapper">
                    <profile-image
                        v-if="user.id"
                        :has-not-watched-stories="user.hasNotWatchedStories"
                        :is-exist-story="user.isExistStory"
                        size="136"
                        :img="profileImage"
                        :username="user.name"
                        :id="user.id"
                        class="mt-6"
                    />
                </div>
            </v-row>
            <v-row justify="center">
                <div class="name mt-3 d-flex justify-center align-center">
                    <span>
                        {{ user.name }}
                    </span>
                    <verified-icon
                        v-if="user.verified"
                        :height="20" :width="20" class="ml-1" />
                    <span @click="handleShowInformDialog($t('dialogs.holiday_mood.other_user_info', { user_name: user.name }))">
                        <holiday-mood-icon v-if="user.on_holiday_mood"/>
                    </span>
                </div>
            </v-row>
            <v-row align="center" justify="center">
                <span class="bio mr-10 ml-10">{{ user.bio }}</span>
            </v-row>
            <v-row
                v-if="!isOwnPage"
                class="icons-wrapper"
                align="center"
                justify="center"
            >
                <!--                <app-icon icon="telegram" @click="() => {}"/>-->
                <send-message :companion="user" />

                <div v-if="this.showSubscribe" class="top-icon">
                    <router-link v-if="user.hasTips" :to="getSecondMenu">
                        <!-- <app-icon icon="secondMenu" /> -->
                        <menu-icon 
                            width="30"
                            height="32"/>
                    </router-link>
                    <div v-else @click="handleShowInformDialog($t('dialogs.has_no_tips'))">
                        <menu-icon 
                            width="30"
                            height="32"/>
                        <!-- <app-icon icon="secondMenu" /> -->
                    </div>
                </div>

                <div v-if="this.showSubscribe" class="top-icon">
                    <router-link to="">
                       <!-- <app-icon icon="largeDollar" @click="handleShowTips" /> -->
                    <dollor-icon 
                        width="35"
                        height="35" 
                        @click="handleShowTips"/>
                    </router-link>
                </div>

                <div v-if="user.verified" @click="handleStreamsButtonClick()" class="mt-1"
                    :class="{'is-user-live': isUserLive}">
                        <live-stream-icon :icon-color="isUserLive ? '#ff0000' : '#ffffff'"/>
                </div>

                <div v-if="this.showSubscribe && user.dream_goal.data.is_activated == 1" class="top-icon">
                    <router-link to="">
                    <dream-goal-icon 
                        width="35"
                        height="35" 
                        @click="handleShowDreamGoalTips"/>
                    </router-link>
                </div>
            </v-row>

            <v-row
                v-if="!isOwnPage"
                class="icons-wrapper"
                align="center"
                justify="center"
            >
                <template v-if="user.amazon || user.instagram || user.twitter">
                    <a v-if="user.amazon" :href="user.amazon">
                        <img
                            :src="require('@/assets/images/logo/amazon.png')"
                            alt="x"
                            title="x"
                            width="30px"
                        />
                    </a>
                    <a v-if="user.instagram" :href="user.instagram">
                        <img
                            :src="require('@/assets/images/logo/instagram.png')"
                            alt="x"
                            title="x"
                            width="30px"
                        />
                    </a>
                    <a v-if="user.twitter" :href="user.twitter">
                        <img
                            :src="require('@/assets/images/logo/x.png')"
                            alt="x"
                            title="x"
                            width="30px"
                        />
                    </a>
                </template>
            </v-row>
            <v-row
                v-if="showSubscribe"
                class="mr-3 ml-3"
                align="center"
                justify="center"
            >
                <gradient-button
                    v-if="isResubscribeAvailable"
                    :loading="$store.getters.loadingBtn('subscribeBtn')"
                    @click="resubscribeMethod"
                    block
                >
                    {{ $t("dialogs.subscribe_for_free") }}
                </gradient-button>

                <gradient-button
                    v-else-if="
                        (!user.isSubscribed || isResubscribeAvailable) &&
                        Number(user.subscribePrice) > 0
                    "
                    :loading="$store.getters.loadingBtn('subscribeBtn')"
                    @click="subscribeMethod"
                    block
                >
                    {{ $t("dialogs.subscribe_for") }} {{ user.subscribePrice
                    }}{{ currency }} (Per Month)
                </gradient-button>

                <gradient-button
                    v-else-if="
                        (!user.isSubscribed || isResubscribeAvailable) &&
                        Number(user.subscribePrice) === 0
                    "
                    :loading="$store.getters.loadingBtn('subscribeBtn')"
                    @click="subscribeMethod"
                    block
                >
                    {{ $t("dialogs.subscribe_for_free") }}
                </gradient-button>

                <gradient-button
                    v-else
                    :loading="$store.getters.loadingBtn('unsubscribeBtn')"
                    @click="unsubscribeMethod"
                    block
                >
                    <app-icon icon="check" size="18" class="mr-3 done" />
                    {{ $t("dialogs.subscribed") }}!
                </gradient-button>

                <div
                    v-if="isResubscribeAvailable"
                    class="text-center grey--text text-body-2"
                >
                    {{ $t("dialogs.active_until") }}
                    {{
                        formatDate(
                            convertUTCDatetimeToLocal(
                                user.subscriptionInfo.expired_at
                                    .replace("T", " ")
                                    .replace(".000000Z", "")
                            )
                        )
                    }}
                </div>
            </v-row>
        </v-container>

        <tip-menu-dialog
            @close="isShowTips = false"
            v-if="isShowTips"
            :target-verified="user.verified"
            :target-username="user.name"
            :has-radio="false"
            @save="sendTip"
        />
        <bottom-bar :active="true" :model="false"> </bottom-bar>
        <subscribe-dialog
            v-if="subscribeInfo"
            :price="user.subscribePrice"
            :subscription="user.subscription"
            :subscription-bundles="user.subscription.bundles || defaultBundles"
            @subscribe="handleSubscribe"
            @close="closeSubscribeInfo"
        />
        <resubscribe-dialog
            v-if="resubscribeInfo"
            @subscribe="handleResubscribe"
            @close="closeSubscribeInfo"
        />
        <unsubscribe-dialog
            v-if="unsubscribeWarning"
            @unsubscribe="handleUnsubscribe"
            @close="closeUnsubscribeWarning"
        />
        <insufficient-funds-dialog
            v-if="insufficientFundsError"
            @close="handleCloseInsufficientFundsError"
        />
        <direct-message-dialog
            v-if="showDirectMessageDialog"
            :subscribe_price="subscribePrice"
            :direct_price="directPrice"
            @subscribe="handleSubscribe"
            @direct-message="handleDirectMessage"
            @close="handleCloseDirectMessageDialog"
        />
        <report-message-dialog
            v-if="showReportMessageDialog"
            @report="handleSendReportMessage"
            @close="closeReportMessageDialog"
        />
        <inform-dialog
            :text="InformDialogText"
            v-if="showInformDialog"
            @close="showInformDialog = false"
        />
        <no-streams-scheduled-dialog
            v-if="showNoStreamsScheduledDialog"
            :username="user.name"
            @close="showNoStreamsScheduledDialog = false"
        />
        <insufficient-funds-dialog
            v-if="showInsufficientFundsDialog"
            @close="showInsufficientFundsDialog = false"
        />
        <dream-goal-dialog
            @close="isShowDreamGoalTips = false"
            v-if="isShowDreamGoalTips"
            :target-verified="user.verified"
            :target-username="user.name"
            :target-dream-goal="user.dream_goal.data"
            :has-radio="false"
            @save="sendCreatorDreamGoalTip"
        />
        <app-transition>
            <Dialog
                @no="closeBlockDialog"
                @yes="handleUserBlock"
                v-if="isBlockDialog"
                >{{ $t("dialogs.are_you_sure") }}
            </Dialog>
        </app-transition>
    </div>
</template>

<script>
import BottomBar from "@/components/app/navigation/BottomBar";
import { mapState, mapActions, mapMutations } from "vuex";
import GradientButton from "@/components/app/button/GradientButton";
import FollowButton from "@/components/app/button/FollowButton";
import ProfileImage from "@/components/app/profile/ProfileImage";
import SwipeTipMenu from "@/components/app/profile/SwipeTipMenu";
import { INSUFFICIENT_FUNDS_ERROR, CURRENCY_SYMBOL } from "@/configs/constants";
import SubscribeDialog from "@/components/app/dialogs/SubscribeDialog";
import ResubscribeDialog from "@/components/app/dialogs/SubscribeDialog";
import UnsubscribeDialog from "@/components/app/dialogs/UnsubscribeDialog";
import InsufficientFundsDialog from "@/components/app/dialogs/InsufficientFundsDialog";
import DirectMessageDialog from "@/components/app/dialogs/DirectMessageDialog";
import ReportMessageDialog from "@/components/app/dialogs/ReportMessageDialog";
import InformDialog from "@/components/app/dialogs/InformDialog";
import SendMessage from "@/components/app/message/SendMessage";
import Dialog from "@/components/app/setting/YesNoDialog";
import IconMenu from "@/assets/images/icons/menu.svg?inline";
import * as clipboardy from "clipboardy";
import { DOMAIN_NAME, SERVER_PROTOCOL } from "@/configs/constants";
import NoStreamsScheduledDialog from "@/components/app/dialogs/NoStreamsScheduledDialog.vue";
import {
    convertUTCDatetimeToLocal,
    formatDate,
    parseDate,
} from "../../../../helpers/functions";
import VerifiedIcon from "@/components/app/common/VerifiedIcon";
import TipMenuDialog from "@/components/app/dialogs/TipMenuDialog";
import DreamGoalDialog from "@/components/app/dialogs/CreatorDreamGoalDialog";

import DollorIcon from "@/assets/images/icons/new_coin.svg?inline";
import MenuIcon from "@/assets/images/icons/main_menu.svg?inline";
import DreamGoalIcon from "@/assets/images/icons/piggy-bank.svg?inline";
import LiveStreamIcon from "@/components/app/common/LiveStreamIcon.vue";
import HolidayMoodIcon from "@/components/app/icons/HolidayMoodIcon";

export default {
    name: "otherUser",
    data: () => ({
        unsubscribeWarning: false,
        subscribeInfo: false,
        resubscribeInfo: false,
        insufficientFundsError: false,
        isShowTips: false,
        currency: CURRENCY_SYMBOL,
        showDirectMessageDialog: false,
        showReportMessageDialog: false,
        isBlockDialog: false,
        showInsufficientFundsDialog: false,
        showNoStreamsScheduledDialog: false,
        isShowDreamGoalTips:false,
        defaultBundles: [
            {
                isEnabled: true,
                period: 3,
                discount: 15,
            },
            {
                isEnabled: true,
                period: 6,
                discount: 25,
            },
            {
                isEnabled: true,
                period: 12,
                discount: 50,
            },
        ],
        displayUserStory: false,
        InformDialogText: null,
        showInformDialog: false,
    }),
    props: {
        width: {
            type: Number,
            default: 0,
        },
        height: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        ...mapState({
            profileImage: (state) => state.otherUser.user.profileImage,
            user: (state) => state.otherUser.user,
            currentUser: (state) => state.user.user.data,
        }),
        isOwnPage() {
            return this.user.id === this.$auth.user().id;
        },
        showSubscribe() {
            return this.user.verified && !this.isOwnPage;
        },
        id() {
            if (typeof this.$route.params.slug !== "undefined") {
                return this.user.id;
            }
            return this.$route.params.id;
        },
        subscribePrice() {
            return this.user.subscribePrice;
        },
        directPrice() {
            return this.user.directMessagePrice;
        },
        userlink() {
            return "/" + this.user.slug;
        },
        userlinkFull() {
            return `${SERVER_PROTOCOL}://${DOMAIN_NAME}${this.userlink}`;
        },
        getSecondMenu() {
            return { name: "user.tips.view", params: { id: this.user.id } };
        },
        isResubscribeAvailable() {
            return (
                this.user &&
                this.user.subscriptionInfo &&
                this.user.subscriptionInfo.cancelled &&
                new Date() <
                    parseDate(
                        this.user.subscriptionInfo.expired_at
                            .replace("T", " ")
                            .replace(".000000Z", "")
                    )
            );
        },
        getWidth() {
            return this.width > 0 ? this.width : 30;
        },
        getHeight() {
            return this.height > 0 ? this.height : 30;
        },
        isUserLive() {
            return this.user?.stream?.is_live
        },
    },
    methods: {
        formatDate,
        convertUTCDatetimeToLocal,
        ...mapActions([
            "setOtherUser",
            "setOtherUserBySlug",
            "sendUserTip",
            "subscribe",
            "resubscribe",
            "unsubscribe",
            "sendBlockedUser",
            "sendUnblockedUser",
            "sendReportUser",
            "sendTipMessage",
            "buyDirectMessage",
            "sendDreamGoalTip",
        ]),
        ...mapMutations(["showSnackBar"]),

        async sendTip(model) {
            await this.sendTipMessage({
                user_id: this.user.id,
                message: model.text,
                ...model,
            })
                .then(() => {
                    this.$router.push({
                        name: "user.own.chat",
                        params: { id: this.user.id },
                    });
                })
                .catch((e) => {
                    if (e.response.data.err_key === INSUFFICIENT_FUNDS_ERROR) {
                        this.insufficientFundsError = true;
                    }
                });
        },
        openBlockDialog() {
            this.isBlockDialog = true;
        },
        closeBlockDialog() {
            this.isBlockDialog = false;
        },
        handleUserBlock() {
            if (this.user.blocked) {
                this.sendUnblockedUser({ id: this.user.id }).then(() => {
                    this.$store.commit(
                        "showSnackBar",
                        this.$t("messages.success")
                    );
                });
            } else {
                this.sendBlockedUser({ id: this.user.id }).then(() => {
                    this.$store.commit(
                        "showSnackBar",
                        this.$t("messages.success")
                    );
                });
            }
            this.closeBlockDialog();
            this.setOtherUser(this.id);
        },
        handleSendReportMessage(message) {
            this.sendReportUser({
                defendant_id: this.id,
                accusation: message,
            }).then(() => {
                this.$store.commit("showSnackBar", this.$t("messages.success"));
            });
            this.closeReportMessageDialog();
        },
        openReportMessageDialog() {
            this.showReportMessageDialog = true;
        },
        closeReportMessageDialog() {
            this.showReportMessageDialog = false;
        },
        handleProfileLink() {
            clipboardy.write(this.userlinkFull);
            this.$store.commit(
                "showSnackBar",
                this.$t("dialogs.copied_clipboard")
            );
        },
        async subscribeMethod() {
            this.subscribeInfo = true;
        },
        resubscribeMethod() {
            this.resubscribeInfo = true;
        },
        subscribeFreeMethod() {
            this.handleSubscribe();
        },
        async handleSubscribe(bundleIndex) {
            this.subscribeInfo = false;
            this.showDirectMessageDialog = false;
            await this.subscribe({
                id: this.id,
                bundle:
                    !bundleIndex && bundleIndex !== 0
                        ? null
                        : this.user.subscription.bundles
                        ? this.user.subscription.bundles[bundleIndex]
                        : this.defaultBundles[bundleIndex],
            })
                .then(() => {
                    this.showSnackBar(this.$t("dialogs.subscribed"));
                })
                .catch((error) => {
                    if (error.response.data.err_msg) {
                        this.showInsufficientFundsDialog = true;
                        return;
                    }

                    this.showSnackBar(error.response.data.err_msg);
                });
            await this.setOtherUser(this.id);
        },
        async handleResubscribe() {
            this.resubscribeInfo = false;
            this.showDirectMessageDialog = false;
            await this.resubscribe(this.id)
                .then((res) => {
                    this.showSnackBar(this.$t("dialogs.subscribed"));
                })
                .catch(() =>
                    this.showSnackBar(this.$t("messages.something_went_wrong"))
                );
            await this.setOtherUser(this.id);
        },
        async unsubscribeMethod() {
            this.unsubscribeWarning = true;
            await this.setOtherUser(this.id);
        },
        async handleUnsubscribe(event) {
            this.unsubscribeWarning = false;
            await this.unsubscribe({ id: this.id, reason: event.reason }).then(
                (res) => {
                    if (typeof res.redirectUrl !== "undefined") {
                        this.$window.location = res.redirectUrl;
                    }
                }
            );
            await this.setOtherUser(this.id);
        },
        async clickFollow() {
            await this.setOtherUser(this.id);
        },
        closeUnsubscribeWarning() {
            this.unsubscribeWarning = false;
        },
        closeSubscribeInfo() {
            this.subscribeInfo = false;
            this.resubscribeInfo = false;
        },
        handleCloseInsufficientFundsError() {
            this.insufficientFundsError = false;
        },
        handleCloseDirectMessageDialog() {
            this.showDirectMessageDialog = false;
        },
        async handleDirectMessage() {
            await this.buyDirectMessage(this.id)
                .then((res) => {
                    if (typeof res.redirectUrl !== "undefined") {
                        this.$window.location = res.redirectUrl;
                    }
                    if (typeof res.success !== "undefined") {
                        this.$store.commit("showSnackBar", res.success);
                        this.setOtherUser(this.id);
                        const chat =
                            !this.user.directMessageTurnOff &&
                            this.user.canSendMessages
                                ? {
                                      name: "user.own.chat",
                                      params: { id: this.user.id },
                                  }
                                : null;
                        if (chat) {
                            this.$router.push(chat);
                        }
                    }
                })
                .catch((e) => {
                    if (e.response.data.err_key === INSUFFICIENT_FUNDS_ERROR) {
                        this.insufficientFundsError = true;
                    }
                });
            await this.setOtherUser(this.id);
            this.handleCloseDirectMessageDialog();
        },

        handleShowTips() {
            this.isShowTips = true;
        },
        async handleStreamsButtonClick() {
            const promise =
                typeof this.$route.params.slug !== "undefined"
                    ? this.setOtherUserBySlug(this.$route.params.slug)
                    : this.setOtherUser(this.$route.params.id);

            await promise.catch(() => {
                this.$router.push({ name: "404" });
            });

            if (!!this.user.stream) {
                this.$router.push({
                    name: "otherUser.profile.viewStreams",
                    params: { slug: this.user.slug },
                });
                return;
            }

            this.showNoStreamsScheduledDialog = true;
        },
        handleBackNav() {
            if (this.$route.params.from && this.$route.params.from == 'content') {
                this.displayUserStory = true
            }
            else {
                this.$router.push({
                    name: this.$route.params.from
                        ? this.$route.params.from
                        : 'search',
                })
            }
        },
        handleShowInformDialog(text) {
            this.InformDialogText = text;
            this.showInformDialog = true;
        },
        handleShowDreamGoalTips() {
            this.isShowDreamGoalTips = true;
        },
        async sendCreatorDreamGoalTip(model) {
            await this.sendDreamGoalTip({
                user_id: this.user.id,
                ...model,
            })
                .then((res) => {
                    this.showSnackBar(res.msg)
                })
                .catch((e) => {
                    if (e.response.data.err_key === INSUFFICIENT_FUNDS_ERROR) {
                        this.insufficientFundsError = true;
                    }
                });
        },
    },
    async mounted() {
        const promise =
            typeof this.$route.params.slug !== "undefined"
                ? this.setOtherUserBySlug(this.$route.params.slug)
                : this.setOtherUser(this.$route.params.id);

        await promise.catch(() => {
            this.$router.push({ name: "404" });
        });

        this.$mixpanel.identifyUser(this.currentUser.email);
        this.$mixpanel.track('page_view', {
            page: "Other user profile",
            visited_profile: this.user.email,
        });
    },
    components: {
        NoStreamsScheduledDialog,
        UnsubscribeDialog,
        SubscribeDialog,
        ResubscribeDialog,
        InsufficientFundsDialog,
        ProfileImage,
        GradientButton,
        FollowButton,
        BottomBar,
        SwipeTipMenu,
        DirectMessageDialog,
        ReportMessageDialog,
        InformDialog,
        SendMessage,
        Dialog,
        IconMenu,
        VerifiedIcon,
        TipMenuDialog,
        DollorIcon,
        MenuIcon,
        LiveStreamIcon,
        HolidayMoodIcon,
        DreamGoalIcon,
        DreamGoalDialog
    },
};
</script>

<style scoped lang="scss">
@import "@/sass/modules/_variables";

.v-menu__content {
    border-radius: 0px;
}

.UserControls {
    &-item {
        .v-list-item__title {
            color: black;
            cursor: pointer;
        }
    }

    &-list {
        background-color: #ffffff !important;
        border-radius: 20px 0px 20px 20px !important;
    }
}

.socials {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.top-icon {
    font-size: 28px;
}

.ProfileImage-wrapper {
    width: 148px;
    position: relative;

    &.without-circle .follow-button {
        right: 10px;
        bottom: 10px;
    }
}

.profile-image {
    height: 136px;
    width: 136px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: whitesmoke;
    border: 6px solid black;
    object-fit: cover;
}

.image-container {
    height: 148px;
    width: 148px;
    border-radius: 50%;
    padding-left: 6px;
    padding-top: 6px;
}

.story-exist {
    background: $grey-background;
}

.has-not-watched-stories {
    background: $accent-background;
}

.name {
    font-size: 1.5em;
}

.counters {
    font-size: 1.5em;
}

.hint {
    display: block;
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.6);
}

.linked {
    text-decoration: none;
}

.bio {
    color: rgba(255, 255, 255, 0.7);
    text-align: justify;
    line-height: 1.2em;
}
.icons-wrapper {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 24px;
}
.top-icon {
    text-align: center;
}

.top-icon > div, .top-icon > a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.done {
    border: 1px solid white;
    border-radius: 50%;
    padding: 4px;
}

ul.style-dashed {
    list-style: none;
}

ul.style-dashed li:before {
    content: "—";
    position: relative;
    left: -5px;
}

.is-user-live {
    animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
